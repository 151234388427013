import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import moment from "moment";

import { db } from "../firebase-config";
import PlansStateContext from "../context/PlansStateContext";

const WorkoutPage = () => {
  const { workoutPlanID } = useParams();
  const plansStateContext = useContext(PlansStateContext);
  const workoutPlan = plansStateContext.workoutPlan;
  const setWorkoutPlan = plansStateContext.setWorkoutPlan;
  const setWorkoutDocId = plansStateContext.setWorkoutDocId;

  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("workutid", workoutPlanID);
    if (workoutPlanID) {
      const getWorkout = async () => {
        const docRef = doc(db, "workoutPlansCollection", workoutPlanID);
        const docSnap = await getDoc(docRef);
        console.log("Document data:", docSnap.data());
        setWorkoutPlan(docSnap.data().workoutPlan);
        setWorkoutDocId(workoutPlanID);
      };
      getWorkout();

      //analytics
      const workoutPlanRef = doc(db, "workoutPlansCollection", workoutPlanID);
      updateDoc(workoutPlanRef, {
        analyticsPageViews: arrayUnion({
          createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        }),
      });
    }
  }, []);

  // const workoutPlan = [
  //   {
  //     //   analytics: null,
  //     //   id: "workoutplan1",
  //     day: "Day 1 – Chest and Triceps",
  //     exercises: [
  //       {
  //         name: "Chest Press (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=NIDP5T6enEM",
  //       },
  //       {
  //         name: "Tricep Pushdowns (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=1nk1y7Uy0Cw",
  //         // "https://www.youtube.com/watch?v=1nk1y7Uy0Cw&ab_channel=WavvyFitness",
  //       },
  //       {
  //         name: "Treadmill Walking (15 minutes)",
  //         video: "https://www.youtube.com/watch?v=FNqAF-9uVO0",
  //         timeMinutes: 15,
  //       },
  //     ],
  //   },
  //   {
  //     day: "Day 2 – Back and Biceps",
  //     exercises: [
  //       {
  //         name: "Lat pull down (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=9tVPuc_zhec",
  //       },
  //       {
  //         name: "Dumbbell bicep curls (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=sBwJIhdu6JI",
  //       },
  //       {
  //         name: "Treadmill Walking (15 minutes)",
  //         video: "https://www.youtube.com/watch?v=FNqAF-9uVO0",
  //         timeMinutes: 15,
  //       },
  //     ],
  //   },
  //   {
  //     day: "Day 3 – Legs",
  //     exercises: [
  //       {
  //         name: "Squats (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=2HIgg3MG29k",
  //       },
  //       {
  //         name: "Leg extension (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video: "https://www.youtube.com/watch?v=8Jqof7z3QYM",
  //       },
  //     ],
  //   },
  // ];

  const toggleAccordion = async (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);

    const workoutPlanRef = doc(db, "workoutPlansCollection", workoutPlanID);
    await updateDoc(workoutPlanRef, {
      analyticsToggles: arrayUnion({
        createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        workoutToggled: workoutPlan[index].day,
      }),
    });
  };

  const startWorkout = async (dayIndex) => {
    // navigate(`/timer`);
    navigate(`/timer/${dayIndex}`);
    const workoutPlanRef = doc(db, "workoutPlansCollection", workoutPlanID);
    await updateDoc(workoutPlanRef, {
      analyticsWorkoutStarted: arrayUnion({
        createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        workoutStarted: workoutPlan[dayIndex].day,
      }),
    });
  };

  return (
    <>
      {workoutPlan && (
        <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
          <h1>Workout Plan</h1>
          <p>
            Here is this week’s workout—we will start off light to get you going
            to the gym consistently, then we will ramp it up each week!
          </p>
          {workoutPlan.map((day, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <button
                onClick={() => toggleAccordion(index)}
                style={{
                  width: "100%",
                  textAlign: "left",
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {day.day}
                <span
                  style={{
                    transform:
                      expandedIndex === index
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    transition: "0.3s",
                  }}
                >
                  ▶
                </span>
              </button>
              {expandedIndex === index && (
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderTop: "none",
                  }}
                >
                  {day.exercises.map((exercise, i) => (
                    <div key={i} style={{ marginBottom: "10px" }}>
                      <p style={{ margin: "5px 0" }}>{`${i + 1}. ${
                        exercise.name
                      }`}</p>
                      <iframe
                        width="100%"
                        height="200"
                        src={exercise.video.replace("watch?v=", "embed/")}
                        title={exercise.name}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ))}
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button
                      onClick={() => startWorkout(index)}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "block",
                        margin: "0 auto",
                      }}
                    >
                      Start Guided Workout
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default WorkoutPage;
