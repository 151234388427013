import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import moment from "moment";

import { db } from "../firebase-config";
import PlansStateContext from "../context/PlansStateContext";

const DietPlan = () => {
  const { dietPlanID } = useParams();
  // const plansStateContext = useContext(PlansStateContext);
  // const dietPlan = plansStateContext.dietPlan;
  // const setDietPlan = plansStateContext.setDietPlan;
  // const setWorkoutDocId = plansStateContext.setWorkoutDocId;

  const [dietPlan, setDietPlan] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("dietID", dietPlanID);
    if (dietPlanID) {
      const getWorkout = async () => {
        const docRef = doc(db, "dietPlansCollection", dietPlanID);
        const docSnap = await getDoc(docRef);
        console.log("Document data:", docSnap.data());
        setDietPlan(docSnap.data().dietPlan);
        // setWorkoutDocId(dietPlanID);
      };
      getWorkout();

      //analytics
      const dietPlanRef = doc(db, "dietPlansCollection", dietPlanID);
      updateDoc(dietPlanRef, {
        analyticsPageViews: arrayUnion({
          createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        }),
      });
    }
  }, []);

  // const dietPlan = [
  //   {
  //     //   analytics: null,
  //     //   id: "dietplan1",
  //     group: "Breakfast Ideas",
  //     recipes: [
  //       {
  //         name: "Greek Yogurt Parfait",
  //         description:
  //           "Layer Greek yogurt, fresh berries, and granola for a quick, high-protein start.",
  //         image:
  //           "https://simplyhomecooked.com/wp-content/uploads/2021/08/yogurt-parfait-5.jpg",
  //       },
  //       {
  //         name: "Protein Pancakes",
  //         description:
  //           "Pancakes made with protein powder, oats, and egg whites.",
  //         image:
  //           "https://images.immediate.co.uk/production/volatile/sites/30/2021/02/Protein-pancakes-b64bd40.jpg?resize=768,574",
  //       },
  //     ],
  //   },
  //   {
  //     group: "Lunch Ideas",
  //     recipes: [
  //       {
  //         name: "Greek Yogurt Parfait",
  //         description:
  //           "Layer Greek yogurt, fresh berries, and granola for a quick, high-protein start.",
  //         image:
  //           "https://simplyhomecooked.com/wp-content/uploads/2021/08/yogurt-parfait-5.jpg",
  //       },
  //     ],
  //   },
  //   {
  //     group: "Dinner Ideas",
  //     recipes: [
  //       {
  //         name: "Greek Yogurt Parfait",
  //         description:
  //           "Layer Greek yogurt, fresh berries, and granola for a quick, high-protein start.",
  //         image:
  //           "https://simplyhomecooked.com/wp-content/uploads/2021/08/yogurt-parfait-5.jpg",
  //       },
  //     ],
  //   },
  //   {
  //     group: "Snack Ideas",
  //     recipes: [
  //       {
  //         name: "Greek Yogurt Parfait",
  //         description:
  //           "Layer Greek yogurt, fresh berries, and granola for a quick, high-protein start.",
  //         image:
  //           "https://simplyhomecooked.com/wp-content/uploads/2021/08/yogurt-parfait-5.jpg",
  //       },
  //     ],
  //   },
  // ];

  const toggleAccordion = async (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);

    const dietPlanRef = doc(db, "dietPlansCollection", dietPlanID);
    await updateDoc(dietPlanRef, {
      analyticsToggles: arrayUnion({
        createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        groupToggled: dietPlan[index].group,
      }),
    });
  };

  const startWorkout = async (groupIndex) => {
    // navigate(`/timer`);
    navigate(`/timer/${groupIndex}`);
    const dietPlanRef = doc(db, "dietPlansCollection", dietPlanID);
    await updateDoc(dietPlanRef, {
      analyticsWorkoutStarted: arrayUnion({
        createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
        workoutStarted: dietPlan[groupIndex].group,
      }),
    });
  };

  return (
    <>
      {dietPlan && (
        <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
          <h1>Diet Plan</h1>
          <p>
            Aim to eat 4 times a day to build your muscle. Recipe ideas will
            refresh every week!
          </p>
          {dietPlan.map((group, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <button
                onClick={() => toggleAccordion(index)}
                style={{
                  width: "100%",
                  textAlign: "left",
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {group.group}
                <span
                  style={{
                    transform:
                      expandedIndex === index
                        ? "rotate(90deg)"
                        : "rotate(0deg)",
                    transition: "0.3s",
                  }}
                >
                  ▶
                </span>
              </button>
              {expandedIndex === index && (
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderTop: "none",
                  }}
                >
                  {group.recipes.map((recipe, i) => (
                    <div key={i} style={{ marginBottom: "10px" }}>
                      <p style={{ margin: "5px 0", fontWeight: "bold" }}>{`${
                        i + 1
                      }. ${recipe.name}`}</p>
                      <p>{recipe.description}</p>
                      <img
                        // width="100%"
                        height="250"
                        src={recipe.image}
                        title={recipe.name}
                        frameBorder="0"
                        allowFullScreen
                        style={{ marginBottom: 30 }}
                      ></img>
                    </div>
                  ))}
                  {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <button
                      onClick={() => startWorkout(index)}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "block",
                        margin: "0 auto",
                      }}
                    >
                      Start Guided Workout
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DietPlan;
