import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import moment from "moment";

import { db } from "../firebase-config";
import PlansStateContext from "../context/PlansStateContext";

const TimerPage = ({ workoutPlan }) => {
  // const workoutPlan = [
  //   {
  //     analytics: null,
  //     id: "workoutplan1",
  //     day: "Day 1 – Chest and Triceps",
  //     exercises: [
  //       {
  //         name: "Chest Press (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video:
  //           "https://www.youtube.com/watch?v=NIDP5T6enEM&ab_channel=PlanetFitnessBeginner",
  //       },
  //       {
  //         name: "Tricep Pushdowns (3 sets of 10 reps)",
  //         sets: 3,
  //         reps: 10,
  //         video:
  //           "https://www.youtube.com/watch?v=1nk1y7Uy0Cw&ab_channel=WavvyFitness",
  //       },
  //       {
  //         name: "Treadmill Walking (15 minutes)",
  //         video: "https://www.youtube.com/watch?v=FNqAF-9uVO0",
  //         timeMinutes: 15,
  //       },
  //     ],
  //   },
  // ];

  const { dayIndex } = useParams();
  const plansStateContext = useContext(PlansStateContext);
  const workoutDocId = plansStateContext.workoutDocId;

  const navigate = useNavigate();
  const exercises = workoutPlan[dayIndex].exercises;

  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [currentSet, setCurrentSet] = useState(1);
  const [isResting, setIsResting] = useState(false);
  const [restTime, setRestTime] = useState(120);
  const [timerActive, setTimerActive] = useState(false);

  const totalRestTime = 120;
  const currentExercise = exercises[currentExerciseIndex];

  // Handle set completion
  const handleSetCompletion = async () => {
    const workoutPlanRef = doc(db, "workoutPlansCollection", workoutDocId);
    if (currentSet < currentExercise.sets) {
      setCurrentSet(currentSet + 1);
      setIsResting(true);
      setTimerActive(true); // Start rest timer

      updateDoc(workoutPlanRef, {
        analyticsWorkoutProgress: arrayUnion({
          createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
          currentExercise: currentExercise.name,
          currentSet: currentSet,
        }),
      });
    } else {
      if (currentExerciseIndex < exercises.length - 1) {
        setCurrentExerciseIndex(currentExerciseIndex + 1);
        setCurrentSet(1);

        updateDoc(workoutPlanRef, {
          analyticsWorkoutProgress: arrayUnion({
            createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
            currentExercise: currentExercise.name,
            currentSet: currentSet,
          }),
        });
      } else {
        navigate("/congrats");
        updateDoc(workoutPlanRef, {
          analyticsWorkoutProgress: arrayUnion({
            createdAt: moment(Date.now()).format("MMMM Do, YYYY  h:mm:ss a"),
            currentExercise: currentExercise.name,
            currentSet: currentSet,
            // only difference is compeltedWorkout field
            completedWorkout: true,
          }),
        });
      }
    }
  };

  // Handle rest timer
  const handleRestCompletion = () => {
    setIsResting(false);
    setRestTime(totalRestTime);
    setTimerActive(false);
  };

  const skipRest = () => {
    setIsResting(false);
    setRestTime(totalRestTime);
    setTimerActive(false);
  };

  // Timer logic
  useEffect(() => {
    let interval;
    if (timerActive && isResting) {
      interval = setInterval(() => {
        setRestTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            handleRestCompletion();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval); // Cleanup interval
  }, [timerActive, isResting]);

  // Calculate progress percentage
  const progressPercentage = ((totalRestTime - restTime) / totalRestTime) * 100;

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        textAlign: "center",
      }}
    >
      {/* <h1>{currentExercise.name}</h1> */}
      <h1>{`Exercise ${currentExerciseIndex + 1}: ${currentExercise.name}`}</h1>

      {!isResting ? (
        <>
          <p>
            Set {currentSet} of {currentExercise.sets}
          </p>
          <iframe
            width="100%"
            height="200"
            src={currentExercise.video.replace("watch?v=", "embed/")}
            title={currentExercise.name}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <button
            onClick={handleSetCompletion}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Finished my{" "}
            {currentSet === 1 ? "1st" : currentSet === 2 ? "2nd" : "last"} set
          </button>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "200px",
              height: "200px",
            }}
          >
            <svg width="200" height="200" viewBox="0 0 36 36">
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                stroke="#e6e6e6"
                strokeWidth="2"
              />
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                stroke="#4CAF50"
                strokeWidth="2"
                strokeDasharray="100"
                strokeDashoffset={`${100 - progressPercentage}`}
                transform="rotate(-90 18 18)"
                strokeLinecap="round"
              />
            </svg>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              <p>Rest</p>
              {restTime}s
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <button
              onClick={() => setTimerActive(!timerActive)}
              style={{
                marginRight: "10px",
                padding: "10px",
                backgroundColor: "#FFC107",
                color: "black",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {timerActive ? "Pause" : "Resume"}
            </button>
            <button
              onClick={skipRest}
              style={{
                padding: "10px",
                backgroundColor: "#F44336",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Skip Rest
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimerPage;
