import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Support from "./pages/Support";
import Register from "./pages/Register";
import Footer from "./components/Footer";
// import Download from "./pages/Download";
import Fliers from "./pages/Fliers";
import WeeklyPairings from "./pages/WeeklyPairings";
import Internships from "./pages/Internships";

import Download from "./pages/Download";
import PixelTest from "./pages/PixelTest";
import Terms from "./pages/Terms";
// import Download from "./pages/DownloadCopy";
import WorkoutPlan from "./pages/WorkoutPlan";
import TimerPage from "./pages/TimerPage";
import Congrats from "./pages/Congrats";

import PlansStateContext from "./context/PlansStateContext";
import DietPlan from "./pages/DietPlan";

function App() {
  const [workoutPlan, setWorkoutPlan] = useState(null);
  const [workoutDocId, setWorkoutDocId] = useState(null);

  return (
    <PlansStateContext.Provider
      value={{
        workoutPlan,
        setWorkoutPlan,
        workoutDocId,
        setWorkoutDocId,
      }}
    >
      <Router>
        <Routes>
          {/* Ai trainer webview pages */}
          <Route path="/workoutplan/:workoutPlanID" element={<WorkoutPlan />} />
          <Route path="/dietplan/:dietPlanID" element={<DietPlan />} />
          <Route
            path="/timer/:dayIndex"
            element={<TimerPage workoutPlan={workoutPlan} />}
          />
          <Route path="/congrats" element={<Congrats />} />

          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
          <Route
            path="/registration-code-E69D3CFF-F454-4950-A8CA-01A4AFF7D475"
            element={<Register />}
          />
          <Route path="/download" element={<Download />} />
          <Route path="/fliers" element={<Fliers />} />
          <Route path="/weeklypairings" element={<WeeklyPairings />} />
          <Route path="/internships" element={<Internships />} />
          <Route path="/PixelTest" element={<PixelTest />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </PlansStateContext.Provider>
  );
}

export default App;
